<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "dingdiancaiji",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "使用前检查",
                        text:[
                            '#初始环境必须成功(图1)',
                        ],
                        img:[
                            "com/init.png",
                        ]
                    },
                    {
                        title: "功能说明",
                        text:[
                            "#切换到定点采集页",
                            "#按图1说明参数:",
                            "1.采集模式有两种",
                            "(1)以下方勾选作物在小地图采集,此模式需要勾选你要采集的作物(单选),然后移动到指定作物旁边,通过扫描小地图采集" +
                            "此模式支持作物刷新时候移动一点位置的情况,比如铜矿,但是不支持铅矿,因为铅矿每次刷新距离很远",
                            "(2)以角色中心固定作物采集,此模式不需要勾选作物,直接移动作物旁边,比如各种树,铁矿这种不会刷新位置的,扫描角色身边进行采集",
                        ],
                        img:[
                            "1.png",
                        ]
                    },
                    {
                        title: "游戏说明",
                        text:[
                            '#根据以上参数设置,移动到作物旁边,开始任务',
                        ],
                        img:[
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            this.$refs.row.initData(this.list);
        }
    }
</script>

<style scoped>

</style>
